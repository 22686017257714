<template>
	<div>
		<div class="all_container">
			<div class="container">
				<div class="carousel">
					<div class="carousel_left">
						<el-carousel trigger="click" height="440px" arrow="always">
							<el-carousel-item v-for="(item,index) in bannerArr2" :key="item.id">
								<img :src="item.thumb" @click="bannerArrChange(item)">
							</el-carousel-item>
						</el-carousel>
					</div>
					<div class="carousel_right">
						<el-carousel trigger="click" height="440px" arrow="always">
							<el-carousel-item v-for="(item,index) in bannerArr1" :key="item.id">
								<img :src="item.thumb" class="big_img" @click="bannerArrChange(item)">
							</el-carousel-item>
						</el-carousel>
					</div>
				</div>
				<div class="hot_recommand">
					<div class="hot_recommand_left carousel_left">
						<div class="one">
							<el-carousel trigger="click" height="440px" arrow="always">
								<el-carousel-item v-for="(item,index) in bannerArr3" :key="item.id">
									<img :src="item.thumb" @click="bannerArrChange(item)">
								</el-carousel-item>
							</el-carousel>
						</div>
						<div class="two">
							<el-carousel trigger="click" height="440px" arrow="always">
								<el-carousel-item v-for="item in bannerArr4" :key="item.id">
									<img :src="item.thumb" @click="bannerArrChange(item)">
								</el-carousel-item>
							</el-carousel>
						</div>

					</div>
					<div class="hot_recommand_right">
						<div class="hot_img">
							<img src="@/assets/home_icons/hot.png" v-if="lang=='zh'">
							<img style="width: 320px;" src="@/assets/home_icons/hot_bnm.png" v-else>
						</div>
						<div class="all_house">
							<houseItem v-for="(item,index) in recommendList" :key="index" :houseItem="item">
							</houseItem>
						</div>
						<div class="check_more" @click="toHotRecommand">
							<img src="@/assets/home_icons/check_more.png" v-if="lang=='zh'">
							<img src="@/assets/home_icons/check_more_bnm.png" v-else>
						</div>
					</div>
				</div>
				<div class="vip">
					<div class="vip_left"></div>
					<div class="vip_right">
						<div class="vip_img" v-if="vip==0">
							<img src="@/assets/home_icons/vip.png" v-if="lang=='zh'">
							<img src="../../../assets/home_icons/vip_bnm.png" v-else>
						</div>
						<div class="vip_carousel">
							<div class="vip_carousel_left carousel_left2">
								<el-carousel trigger="click" height="260px" arrow="always">
									<el-carousel-item v-for="item in bannerArr5" :key="item.id">
										<img :src="item.thumb" @click="bannerArrChange(item)">
									</el-carousel-item>
								</el-carousel>
							</div>
							<div class="vip_carousel_right carousel_left2">
								<el-carousel trigger="click" height="260px" arrow="always">
									<el-carousel-item v-for="item in bannerArr6" :key="item.id">
										<img :src="item.thumb" @click="bannerArrChange(item)">
									</el-carousel-item>
								</el-carousel>
							</div>
						</div>
						<div class="vip_big_carousel carousel_left2">
							<el-carousel trigger="click" height="260px" arrow="always">
								<el-carousel-item v-for="item in bannerArr7" :key="item.id">
									<img :src="item.thumb" @click="bannerArrChange(item)">
								</el-carousel-item>
							</el-carousel>
						</div>
						<div class="like">
							<div class="like_img">
								<img v-if="lang=='zh'" src="@/assets/home_icons/like.png">
								<img v-else src="../../../assets/home_icons/like_bnm.png">
							</div>
							<div class="all_house">
								<houseItem v-for="(item,index) in likeList" :key="index" :houseItem="item"></houseItem>
							</div>
							<div class="pagination">
								<el-pagination background layout="prev, pager, next,sizes" :total="total"
									@current-change="currentChangeClick" @size-change="sizeChangeClick"
									:current-page="currentPage" :page-size="pageSize" :background="true"
									popper-class="select_bottom" :page-sizes="pageSizes">
								</el-pagination>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import houseItem from '@/components/houseItem.vue';

	export default {
		components: {
			houseItem,
		},
		data() {
			return {
				recommendList: [], //热门推荐列表
				likeList: [], //喜欢列表

				total: 8, //总条数
				currentPage: 1, //当前页数
				pageSize: 16, //每页的条数
				pageSizes: [16, 20, 30, 40, 50, 100],

				bannerArr1: [], //轮播图1
				bannerArr2: [],
				bannerArr3: [],
				bannerArr4: [],
				bannerArr5: [],
				bannerArr6: [],
				bannerArr7: [],

				lang: '',
				vip: '',
			}
		},
		mounted() {
			this.lang = localStorage.getItem('lang');
			this.getRecommend();
			this.getAllLike();
			this.getAds(1, 3);
			this.getAds(2, 4);
			this.getAds(3, 5);
			this.getAds(4, 6);
			this.getAds(5, 7);
			this.getAds(6, 8);
			this.getAds(7, 9);

			// 实现监听localstorage中某个键对应的值的变化
			//根据自己需要来监听对应的key
			window.addEventListener("setItemEvent", (e) => {
				//e.key : 是值发生变化的key
				//例如 e.key==="token";
				//e.newValue : 是可以对应的新值
				if (e.key === "lang") {
					this.lang = e.newValue;
				}
			})

			this.getUserInfo();
		},
		methods: {
			// 轮播图切换事件
			bannerArrChange(item) {
				console.log(item);
				let ads_type = item.ads_type;
				let bind_information_id = item.bind_information_id;
				let href = item.href;
				if (ads_type == 1) {
					if (href) {
						window.open(href)
					}
				} else {
					this.$router.push({
						path: '/detail',
						query: {
							information_id: bind_information_id,
						}
					});
				}
			},
			// 获取用户信息
			getUserInfo() {
				this.$http.userInfo().then(res => {
					if (res.code == 1) {
						this.vip = res.data.vip;
					} else {
						this.$message.info(res.msg);
					}
				})
			},
			// 获取轮播图
			getAds(type, typeid) {
				this.$http.getAds({
					typeid,
				}).then(res => {
					if (res.code == 1) {
						switch (type) {
							case 1:
								this.bannerArr1 = res.data;
								break;
							case 2:
								this.bannerArr2 = res.data;
								break;
							case 3:
								this.bannerArr3 = res.data;
								break;
							case 4:
								this.bannerArr4 = res.data;
								break;
							case 5:
								this.bannerArr5 = res.data;
								break;
							case 6:
								this.bannerArr6 = res.data;
								break;
							case 7:
								this.bannerArr7 = res.data;
								break;
						}
					} else {
						this.$message.info(res.msg);
					}
				})
			},
			// 	去热门推荐
			toHotRecommand() {
				this.$router.push('/hotRecommand');
				// let routeUrl=this.$router.resolve({
				// 	path: '/home/hotRecommand'
				// })
				// window.open(routeUrl.href,'_blank')
			},
			// 获取热门推荐
			getRecommend() {
				this.$http.getRecommend({
					pageNo: 1,
					pageSize: 24,
					is_index: 1,
					type: 1,
				}).then(res => {
					if (res.code == 1) {
						this.recommendList = res.data.data;
					} else {
						this.$message.error(res.data.msg);
					}
					console.log(res);
				}).catch((v) => {
					console.log(v);
				});
			},
			// 获取所有喜欢的
			getAllLike() {
				let data = {
					pageNo: this.currentPage,
					pageSize: this.pageSize,
					is_index: 1,
					type: 2,
				};
				this.$http.getRecommend(data).then(res => {
					console.log(res);
					if (res.code == 1) {
						this.total = res.data.total;
						this.likeList = res.data.data;
					} else {
						this.$message.info(res.msg);
					}
				}).catch(() => {

				})
			},

			currentChangeClick(currentPage) {
				this.currentPage = currentPage;
				this.getAllLike();

			},
			sizeChangeClick(pageSize) {
				this.pageSize = pageSize;
				this.getAllLike();
			},
		},

	}
</script>

<style lang="less" scoped="scoped">
	.big_img {
		width: 948px;
		height: 440px;
		object-fit: fill;
	}

	.all_container {
		background: #F6F7F9;
	}

	.container {
		width: 1200px;
		margin: 0 auto;
		padding-bottom: 64px;
	}

	.carousel {
		display: flex;

		.carousel_left {
			width: 232px;
		}

		.carousel_right {
			width: 968px;
		}
	}


	.hot_recommand {
		box-sizing: border-box;
		display: flex;
		margin-top: 16px;

		.hot_recommand_left {
			width: 232px;

			.two {
				margin-top: 16px;
			}
		}

		.hot_recommand_right {
			width: 968px;
			padding-left: 10px;

			.hot_img {
				display: flex;
				justify-content: center;
				margin-top: 20px;
				margin-bottom: 20px;

				img {
					width: 205px;
					height: 52px;
				}
			}

			.all_house {
				display: flex;
				flex-wrap: wrap;
			}

			.check_more {
				margin-top: 24px;

				&:hover {
					cursor: pointer;
				}

				img {
					width: 120px;
					height: 36px;
				}
			}
		}
	}



	.vip {
		display: flex;
		margin-top: 40px;

		.vip_left {
			width: 232px;
		}

		.vip_right {
			width: 968px;


			.vip_img {

				img {
					width: 928px;
					height: 112px;
				}
			}

			.vip_carousel {
				margin-top: 32px;
				display: flex;
				justify-content: space-between;

				.vip_carousel_left {
					width: 474px;
				}

				.vip_carousel_right {
					width: 474px;
				}
			}

			.vip_big_carousel {
				margin-top: 10px;
				width: 958px;
			}
		}
	}

	.like {
		.like_img {
			margin: 40px 0 20px;

			img {
				width: 316px;
				height: 44px;
			}
		}

		.all_house {
			display: flex;
			flex-wrap: wrap;
		}
	}


	.carousel_left {
		width: 232px;

		/deep/ .el-carousel__arrow {
			top: 72%;
		}

		/deep/ .el-carousel__arrow--left,
		/deep/ .el-carousel__arrow--right {
			width: 24px;
			height: 24px;
		}
	}

	.carousel_left2 {
		width: 232px;

		/deep/ .el-carousel__arrow {}

		/deep/ .el-carousel__arrow--left,
		/deep/ .el-carousel__arrow--right {
			width: 24px;
			height: 24px;
		}
	}


	/deep/.el-carousel__indicators--horizontal {

		.el-carousel__indicator--horizontal button {
			width: 8px;
			height: 8px;
			background: #ffffff;
			border-radius: 50%;
			opacity: 0.5;
		}

		.el-carousel__indicator--horizontal.is-active button {
			width: 24px;
			height: 8px;
			background: #ffffff;
			opacity: 0.5;
			border-radius: 10px;
		}
	}

	.pagination {
		margin-top: 24px;
		display: flex;
		justify-content: flex-end;
	}
</style>
